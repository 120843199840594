import React, { Fragment } from 'react'
import { Link } from 'gatsby';
import logo from '@assets/img/logo.png';
import fb from '@assets/img/web_img/fb.png';
import twtr from '@assets/img/web_img/twtr.png';
import lin from '@assets/img/web_img/lin.png';
import insta from '@assets/img/web_img/insta.png';
import utube from '@assets/img/web_img/utube.png';
import location from '@assets/img/web_img/location.png';
import call from '@assets/img/web_img/call.png';
import mes from '@assets/img/web_img/mes.png';

import '../../../assets/section_new_styles/new_footer.scss'

const data = [
    {
        id: 0,
        title: 'Home',
        route: '/'
    },
    {
        id: 1,
        title: 'About Us',
        route: '/#ourStory'
    },
    {
        id: 2,
        title: 'Product',
        route: '/#animations'
    },
    {
        id: 3,
        title: "Let's Connect",
        route: '/#contact'
    },
    {
        id: 4,
        title: 'Terms of Use',
        route: '/term'
    },
    {
        id: 5,
        title: 'Privacy Policy',
        route: '/privacy'
    }
]

const NewFooter = () => {
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }

    return (
        <footer className='pt-4 px-0 d-flex flex-column align-items-center justify-content-center' style={{ backgroundColor: 'rgb(242, 242, 242)', gap: '1.5rem' }}>
            
            <div className='footer_logo'>
                <Link to = "/">
                    <img src={logo} alt="STEPapp Logo | STEPapp" style={{width: '160px', height: '60px', OObjectFit: 'contain'}} />
                </Link>
            </div>
            <ul className='d-flex flex-wrap align-items-center justify-content-center footer_links mb-0' style={{ gap: '1rem' }}>
                {data.map((item) => (
                    <li key={item.id}>
                        <Link className="navbar-item" 
                        // activeStyle={{
                        //         fontWeight: "bold",
                        //         color: "#f16a24"
                        //     }} 
                            style={{ fontWeight: 700, fontSize: '1rem' }} 
                            // activeClassName="selected" 
                            to={item.route}>{item.title}</Link>
                    </li>
                )
                )}
            </ul>
            <ul className="mb-0 d-flex flex-row mb-3">
                <li className="px-2">
                    <a href="https://www.facebook.com/stepappai/" target="_blank">
                        <img src={fb} alt="fb image" style={{filter: 'grayscale(1)', width: '40px', height: '40px'}} />
                    </a>
                </li>
                <li className="px-2"><a href="https://twitter.com/STEPappai?s=09" target="_blank">
                    <img src={twtr} alt="fb image" style={{filter: 'grayscale(1)', width: '40px', height: '40px'}} />
                </a></li>
                <li className="px-2">
                    <a href="https://in.linkedin.com/company/eduisfun-technologies-pvt-ltd" target="_blank">
                        <img src={lin} alt="linkedin image" style={{filter: 'grayscale(1)', width: '40px', height: '40px'}} />
                    </a></li>
                <li className="px-2"><a href="https://www.instagram.com/stepapp.ai/" target="_blank">
                    <img src={insta} alt="instagram image" style={{filter: 'grayscale(1)', width: '40px', height: '40px'}} />
                </a>
                </li>
                <li className="px-2"><a href="https://www.youtube.com/channel/UCMly3tuVLkIXtrhyC8Xro7w" target="_blank">
                    <img src={utube} alt="instagram image" style={{filter: 'grayscale(1)', width: '40px', height: '40px'}} />
                </a>
                </li>
            </ul>
            <div className='w-100 text-center py-3 d-flex flex-column flex-md-row align-items-center justify-content-center' style={{backgroundColor: 'rgb(44,44,44)'}}>
                <div className='text-white font-bolder' style={{color: '#D1D1D1'}}>© 2023 STEPapp.</div>
                <div className='text-white font-bolder' style={{color: '#D1D1D1'}}> All Rights Reserved. Developed by EduIsFun</div>
            </div>
        </footer>
    )
}

export default NewFooter