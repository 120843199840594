import React from 'react';
import { Link } from 'gatsby';
import logo from '@assets/img/logo.png';
import cross from '@assets/img/cross.png';


const Navbar = class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false,
            navBarActiveClass: '',
            mobile_menu_flag: false,
        }
    }
    
    mobile_menu = () => {
        this.setState({
            mobile_menu_flag:true
        })
    }

    mobile_menu_close = () => {
        this.setState({
            mobile_menu_flag:false
        })
    }

    

    toggleHamburger = () => {
        // toggle the active boolean in the state
        this.setState(
            {
                active: !this.state.active,
            },
            // after state has been updated,
            () => {
                // set the class in state for the navbar accordingly
                this.state.active
                    ? this.setState({
                        navBarActiveClass: 'is-active',
                    })
                    : this.setState({
                        navBarActiveClass: '',
                    })
            }
        )
    }
    componentDidMount(){
       
        var a = window.ChatGen || [];
        if (!a.loaded) {
            var s = document.createElement('script');
            s.type = "text/javascript";
            s.async = true;
            s.src = 'https://app.chatgen.ai/cmp/chat-widget/bot.js';
            var fs = document.getElementsByTagName('script')[0];
            fs.parentNode.insertBefore(s, fs);
            s.onload=function(){
                var yourKey = 'dnPALJkR';
                var widgetKey = {widget_key: yourKey};
                window.ChatGen.init(widgetKey);
            }
        }

    }

    

    render() {
        return (
            <nav
                className="navbar header is-transparent"
                role="navigation"
                aria-label="main-navigation"
            >
                <div className="container">
                    <div className="head_sec">
                    <div className="navbar-brand header_logo">
                        <Link to="/" className="navbar-item" title="Logo">
                            <img src={logo} alt="STEPapp" />
                        </Link>
                        {/* Hamburger menu */}
                        <div
                            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                            data-target="navMenu"
                            role="button"
                            onClick={() => this.toggleHamburger()}
                        >
                            <span />
                            <span />
                            <span />
                        </div>
                    </div>
                    <div
                        id="navMenu"
                        className={`navbar-menu ${this.state.navBarActiveClass}`}
                    >
                        <div className={this.state.mobile_menu_flag === true ? 'mm-layer mm-layer-in' : 'mm-layer'} onClick={() => this.mobile_menu_close()}></div>
                        <div className="header_links">
                            <ul className={this.state.mobile_menu_flag === true ? 'side_menu mob-menu-in' : 'side_menu'}> 
                            <li className="cross" onClick={() => this.mobile_menu_close()}> 
                            {/* <img src="img/cross.png" alt="cross image missing" /> */}
                            <img src={cross} alt="cross image missing" />
                            </li>
                            <li><Link
                            exact
                             className="navbar-item" to="/"
                            //     activeStyle={{
                            //     fontWeight: "bold",
                            //     color: "#f16a24"
                            // }} activeClassName="selected"
                            >
                                 Home </Link></li> 
                            {/* <li><Link className="navbar-item" to="/programmes" activeStyle={{ */}
                            <li><Link className="navbar-item"
                            exact
                             to="/#animations" 
                            //  activeStyle={{
                            //     fontWeight: "bold",
                            //     color: "#f16a24"
                            // }} activeClassName="selected"
                            > 
                            Products </Link></li>
                            {/* <li><Link className="navbar-item" to="/b2b" activeStyle={{ */}
                            {/* <li><Link className="navbar-item" to="/schoolsolution" activeStyle={{
                                fontWeight: "bold",
                                color: "#f16a24"
                            }} activeClassName="selected"> School Solutions </Link></li> */}
                            {/* <li><Link className="navbar-item" to="/partner" 
                            activeStyle={{ fontWeight: "bold", color: "#f16a24"
                            }} activeClassName="selected"> Partner With Us</Link></li> */}
                            {/* <li><Link className="navbar-item" to="/media" 
                            activeStyle={{ fontWeight: "bold", color: "#f16a24"
                            }} activeClassName="selected"> In The Media </Link></li> */}
                            <li><Link className="navbar-item"
                            exact
                             to="/#ourStory" 
                            //  activeStyle={{
                            //     fontWeight: "bold", color: "#f16a24" }} activeClassName="selected"
                                > About Us </Link> </li>
                            {/* <li><Link className="navbar-item" to="/blog" activeStyle={{
                                fontWeight: "bold",
                                color: "#f16a24"
                            }} activeClassName="selected"> Blogs </Link></li> */}
                            {/* <li><Link className="navbar-item" to="/faq" activeStyle={{
                                fontWeight: "bold", color: "#f16a24" }} activeClassName="selected"> FAQs </Link></li> */}
                            <li><Link className="navbar-item"
                            exact
                             to="/#contact"
                            //   activeStyle={{
                            //     fontWeight: "bold",
                            //     color: "#f16a24"
                            // }} activeClassName="selected"
                            > Let's Connect </Link></li>
                            <li>
                            <Link className="navbar-item" to="/skills" target="_blank">
                                <button className="pink_btn">Skills</button>
                            </Link>
                            {/* <a className="navbar-item" target="_blank" href="https://learn.stepapp.in/">
                                <button className="pink_btn">Play The Demo</button>
                            </a> */}
                            </li>
                            </ul> 
                            <div className="mob_box"> 
                            {/* <Link to="learn.stepapp.in" className="pink_btn">Play The Demo</Link> */}
                            {/* <a className="pink_btn" target="_blank"  href="https://learn.stepapp.in/">Play The Demo</a> */}
                                <div className="mob_menu" onClick={() => this.mobile_menu()}>
                                    <span></span>  
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
            </div>
        </nav>
        )
    }
}

export default Navbar